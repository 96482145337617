import React from 'react';
import './Lower.css';
import Email from '../../assets/email.svg';
import Facebook from '../../assets/facebook.svg';
import Instagram from '../../assets/instagram.svg';
import { Link } from 'react-router-dom';

const Lower = () => {
  return (
    <footer className="Footer">
      <div>
        <ul className='icons'>
          <li className='icon-instagram'>
            <a href={'https://www.instagram.com/dbno.ent/'} target='_blank' area-label='Instagram'>
              <img src={ Instagram } alt="Instagram Icon" className='icon__instagram' />
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Lower