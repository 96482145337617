import React from 'react';
import './App.css';
import { Upper, Content, Lower } from './components';


const App = () => {
  return (
    <div className="App">
      <div className='bgimage'>
        <Upper />
        <Content />
        <Lower />
      </div>
    </div>
  )
}

export default App