import React from 'react';
import './Content.css';
import disk from '../../assets/disk.png'


const Content = () => {
  return (
    <div className="Content">
    <img src={ disk } alt="DBNO Disk" className="Disk"/>
    <h1 className="Banner">COMING SOON</h1>
    </div>
  )
}

export default Content