import React from 'react'
import './Upper.css'
import Logo from '../../assets/logo.svg'

const Upper = () => {
  return (
    <div className="header">
      <img src={Logo} alt="DBNO Logo" className='logo'/>
    </div>
  )
}

export default Upper